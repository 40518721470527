<template>
	<div class="gnrhsignin">
		<pre>
   CGM-MS1使用流程，该产品组成包括：传感器组件、助针器组件、发射器组件（发射器和充电组件）。
<b>1）取出CGM-MS1产品各组件进行检查：</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/CGM-MS1-S.png"/></td>
					<td style="width:50%;">传感器组件包装无破损，保持完好。</td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/CGM-MS1-N.png"/></td>
					<td>助针器组件处于释放状态，且中间深孔内为空置状态。</td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/CGM-MS1-T.png"/></td>
					<td>发射器电量为满电状态，与充电组件组合时保持常量。</td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/CGM-MS1-C1.png"/></td>
					<td>充电组件处于空置状态，可与发射器适配。</td>
				</tr>
			</tbody>
		</table>
<b>2）清洁皮肤</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_clean_skin.png"/></td>
					<td style="width:50%;">1. 使用酒精棉擦拭、清洁即将佩戴传感器的皮肤区域，并稍后片刻，等待此区域的皮肤变得干燥。
<b>注意：此步骤有助于将传感器稳定的固定于用户上臂背侧或腹部两侧。佩戴传感器之前，佩戴部位必需清洁且保持干燥，否则传感器可能无法正常粘贴在佩戴部位。</b></td>
				</tr>
			</tbody>
		</table>
<b>3）打开包装</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_unpack1.png"/></td>
					<td style="width:50%;" rowspan="2">1. 检查传感器组件标签确认产品在有效期内，完全撕下传感器组件的铝箔封盖，打开传感器组件，观察顶部平衡杆无异常。
2. 抓取平衡杆中部圆形位置，从垂直方向将平衡杆从灭菌盒中取出，然后观察射针柱与传感器底座是否处于正常位置，即射针柱应处于垂直静止状态。
<b>注意：如果用户发现传感器组件包装存在破损或时已被打开，则不得使用，并需及时联系客服。如果用户发现传感器组件已超有效期，则不得使用，并需及时联系客服。</b></td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_unpack2.png"/></td>
				</tr>
			</tbody>
		</table>
<b>4）组装传感器</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_package1.png"/></td>
					<td style="width:50%;" rowspan="2">1. 将打开包装膜的传感器组件放置在平台、稳定的水平工作台面，并用一只手对其进行固定，防止传感器组件在传感器安装过程中发生不必要的移动。
2. 抓取助针器组件，确认助针器组件处于释放状态，将助针器组件的对准标识与灭菌盒指示符号对齐，然后用力按下助针器组件从而使助针器组件拾取传感器底座，直至无法按动助针器组件（此时会听到“咔哒”一声表示助针器组件已处于弹射状态），此时传感器已被组装完成。
3. 将助针器组件从灭菌盒中提起。确保传感器底座与助针器内表面处于同一水平面。传感器准备就绪，可以开始佩戴传感器。此时检查灭菌盒内部中间台应残留一张透明的离型膜。
<b>注意：现在，助针器组件内可见一个导引针，用户不可触摸助针器组件内部的导引针或将助针器组件再次放入灭菌盒中。另外还需观察传感器需内置于导引针侧壁内，如传感器凸出导引针，则不得使用，并需及时联系客服。</b></td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_package2.png"/></td>
				</tr>
			</tbody>
		</table>
<b>5）佩戴传感器</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_wear1.png"/></td>
					<td style="width:50%;" rowspan="2">1. 将助针器组件放置于已进行清洁并处于干燥状态的即将佩戴传感器的皮肤区域，用力同时按下助针器外壳侧壁的两个按键，此时听到清脆的“咔哒”声，即可将传感器佩戴于用户的上臂背侧或腹部两侧。
<b>注意： 在将助针器组件放置在即将佩戴传感器的皮肤区域之前，请用户不要同时按下助针器组件外壳侧壁的两个按键，以避免助针器内部的部件对用户产生非预期的伤害。</b>
2. 将助针器组件从上臂背侧轻轻地移开。此时可以观察到传感器底座已佩戴于上臂背侧或腹部两侧。观察助针器组件，确保助针器组件处于释放状态，且射针柱处于助针器组件内部。
<b>注意：佩戴传感器时可能会导致轻微的淤青或出血，属于正常现象。若发现流血现象较为严重，应立即移除传感器，然后在其他的皮肤区域佩戴新的传感器。</b>
3. 用手指轻轻按压胶贴周边，确保传感器底座粘贴牢固。</td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_wear2.png"/></td>
				</tr>
			</tbody>
		</table>
<b>6）安装和连接发射器</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_install1.png"/></td>
					<td style="width:50%;" rowspan="4">1. 从充电组件上取出满电状态的发射器。
2. 打开手机应用软件，依次分别扫描传感器组件和发射器的SN号，此时手机应用软件处于待连接发射器状态。
<b>注意： 传感器组件外包装上SN号和二维码需保留，发射器壳体和包装均需保留有二维码，具体扫码流程参见软件使用流程。</b>
3. 取出发射器轻轻移动至传感器底座处，将发射器与传感器底座一端接触一端待卡入，即成一个约30°夹角状态。按压发射器待卡入翘起的一端，使其与传感器底座另一端卡紧。此时发射器上的指示灯应该闪绿灯3次，表示发射器开关接通。
<b>注意：检查发射器与传感器底座的安装情况，四周应该连接紧密，必要时可以按压一下两侧。</b>
4. 点击手机应用软件的“现在连接”按键，启动连接发射器，连接成功后发射器闪绿灯三下，手机应用软件进入水化界面。
<b>注意： 用户将发射器安装到传感器底座后，需尽快使用手机应用软件连接发射器，否则发射器内置的锂电池电量有可能在14天的使用时间内提前被耗尽。</b></td>
				</tr>
				<tr>
					<td style="border-bottom:0px;"><img src="../../../assets/h5Images/icon_install2.png"/></td>
				</tr>
				<tr>
					<td style="border-bottom:0px;"><img src="../../../assets/h5Images/icon_install3.png"/></td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_install4.png"/></td>
				</tr>
			</tbody>
		</table>
<b>7）助针器组件复位</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_reset.png"/></td>
					<td style="width:50%;">1. 取出完成植入的助针器组件，检查其底部中间孔内应该有射针柱残留。
2. 将灭菌盒倒置，将助针器组件与倒置的灭菌盒底部配合，卸载移除钢针组件。
3. 回收助针器组件，擦拭干净，以供下次使用。剩下的传感器组件组成部分作为医疗垃圾处理。</td>
				</tr>
			</tbody>
		</table>
<b>8）传感器的移除和更换</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_remove.png"/></td>
					<td style="width:50%;">1. 佩戴十四天后，需要拆卸发射器与传感器底座，将传感器底座的胶贴边缘向上轻轻的拉起，缓慢地将传感器从上臂背侧或腹部两侧的皮肤上撕下，即可完成传感器的移除。
2. 观察发射器与传感器底座，依托传感器底座卡钩掰扯传感器底座，将发射器与传感器底座分离。
3. 用户需将使用完毕的传感器底座及其组件作为医疗垃圾处理。发射器则清洁干净，以供下次使用。用户可联系客服，进一步了解有关如何处理传感器废弃物的详细情况。
<b>注意：若用户通过持续葡萄监测系统所获得的血糖读数与当前的感受不相符，需检查传感器的佩戴情况，并进行指尖血糖测试以确认您的葡萄糖水平。如果问题仍然存在，移除当前传感器。
注意：传感器的有效工作时长为14天。当传感器已佩戴14天之后，将停止更新并停止上传数据，用户必须按照以上步骤尽快将传感器进行移除。</b></td>
				</tr>
			</tbody>
		</table>
<b>9）发射器充电</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_charge.png"/></td>
					<td style="width:50%;">1. 取出待充电的发射器和充电组件,将发射器一端的两只脚与充电座的中间槽一端的两个孔对齐并卡入,此时发射器与充电组件倾斜约30°夹角，然后按压发射器翘起的另一端直到其卡紧。
2. 安装完毕后应听到“咔哒”一声，此时发射器应该闪绿灯3次，将TypeC数据线接上电源，此时发射器持续闪绿灯，每四秒一次，表示正在充电。
3. 发射器充满电后，指示灯常亮不再闪灯。完成充电后，可以收纳发射器和充电组件已供下次使用。</td>
				</tr>
			</tbody>
		</table>
    CGM-MS2使用流程，该产品一体设计组成，均为一次性耗材。
<b>1）清洁皮肤</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_clean_skin.png"/></td>
					<td style="width:50%;">1. 使用酒精棉擦拭、清洁即将佩戴传感器的皮肤区域，并稍后片刻，等待此区域的皮肤再次变得干燥。
<b>注意：此步骤有助于将传感器稳定的固定于用户上臂背侧或腹部两侧。佩戴传感器之前，佩戴部位必需清洁且保持干燥，否则传感器可能无法正常粘贴在佩戴部位。</b></td>
				</tr>
			</tbody>
		</table>
<b>2）打开包装</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_open1.png"/></td>
					<td style="width:50%;" rowspan="4">1. 检查CGM-MS2产品中包装标签，确认产品在有效期内，从包装盒中取出CGM-MS2产品。
<b>注意：如果用户发现CGM-MS2产品包装存在破损或时已被打开，则不得使用，并需及时联系客服。如果用户发现CGM-MS2产品已超有效期，则不得使用，并需及时联系客服。</b>
2. 一手握住CGM-MS2产品上方的环纹处（弹射机构），另一手按压产品下方保护罩的扳手位置，将保护罩从弹射机构上取下。
<b>注意：取出保护罩过程中，不可同时触碰弹射机构两侧按键。取出保护罩后，弹射机构内可见一个导引针，用户不可触摸弹射机构内部的导引针。另外还需观察传感器需内置于导引针侧壁内，如传感器凸出导引针，则不得使用，并需及时联系客服。</b></td>
				</tr>
				<tr>
					<td style="border-bottom:0px;"><img src="../../../assets/h5Images/icon_open2.png"/></td>
				</tr>
				<tr>
					<td style="border-bottom:0px;"><img src="../../../assets/h5Images/icon_open3.png"/></td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_open4.png"/></td>
				</tr>
			</tbody>
		</table>
<b>3）佩戴发射器和传感器（佩戴部件）</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_wear1.png"/></td>
					<td style="width:50%;" rowspan="2">1. 将弹射机构放置于已进行清洁并处于干燥状态的即将佩戴产品的皮肤区域，用力按下弹射机构外壳侧壁可将发射器和传感器一次性的两个按键，此时听到清脆的“咔哒”声，即佩戴于用户的上臂背侧或腹部两侧。
<b>注意： 在将弹射机构放置在即将佩戴产品的皮肤区域之前，请用户不要同时按下弹射机构外壳侧壁的两个按键，以避免助针器内部的部件对用户产生非预期的伤害。</b>
2. 将弹射机构从佩戴部位轻轻地移开。此时可以观察到发射器和传感器已佩戴于上臂背侧或腹部两侧。
<b>注意：佩戴产品时可能会导致轻微的淤青或出血，属于正常现象。若发现流血现象较为严重，应立即移除产品，然后在其他的皮肤区域佩戴新的产品。</b>
3. 用手指轻轻按压胶贴周边，确保发射器粘贴牢固，至此发射器和传感器植入完成。</td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_wear2.png"/></td>
				</tr>
			</tbody>
		</table>
<b>4）连接发射器</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;border-bottom:0px;"><img src="../../../assets/h5Images/icon_connect1.png"/></td>
					<td style="width:50%;" rowspan="2">1. 打开手机应用软件，扫描CGM-MS2产品上SN号，此时手机应用软件处于待连接发射器状态。
<b>注意： CGM-MS2产品和外包装上SN号和二维码需保留，具体扫码流程参见软件使用流程。</b>
2. 点击手机应用软件的现在连接按键，启动连接发射器，连接成功后发射器闪绿灯三下，手机应用软件进入水化界面。
<b>注意： 用户将发射器安装到传感器底座后，需尽快使用手机应用软件连接发射器，否则发射器内置的一次性锂电池有可能在14天的使用时间内提前被耗尽。</b></td>
				</tr>
				<tr>
					<td><img src="../../../assets/h5Images/icon_install4.png"/></td>
				</tr>
			</tbody>
		</table>
<b>5）传感器和发射器（佩戴部件）移除和更换</b><table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_remove2.png"/></td>
					<td style="width:50%;">1. 佩戴十四天后，需要拆卸发射器与传感器，将胶贴边缘向上轻轻的拉起，缓慢地将佩戴部件从佩戴部位的皮肤上撕下，即可完成佩戴部件的移除。
2. 用户需将使用完毕的传感器和发射器作为医疗垃圾处理。用户可联系客服，进一步了解有关如何处理佩戴部件废弃物的详细情况。
<b>注意：若用户通过持续葡萄监测系统所获得的血糖读数与当前的感受不相符，需检查传感器的佩戴情况，并进行指尖血糖测试以确认您的葡萄糖水平。如果问题仍然存在，移除当前传感器。
注意：产品的有效工作时长为14天。当产品已佩戴14天之后，将停止更新并停止上传数据，用户必须按照以上步骤尽快将产品进行移除。</b></td>
				</tr>
			</tbody>
		</table></pre>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgWidth:"width:100px;"
			}
		},
		created() {
			//获取屏幕宽度，计算一半的宽度。然后去设置
			var width=document.documentElement.clientWidth;
			console.log(width);
			this.imgWidth="width:" + (width/2-30) + "px";
		}
	}
	
</script>

<style scoped="scoped">
	.gnrhsignin {
		min-height: 100%;
		background-color: #fff;
		width: 100%;
		overflow-x: hidden;
	}

	.gnrhsignin h1 {
		text-align: center;
		font-size: 1.2rem;
	}

	.gnrhsignin pre {
		font-size: 1.2rem;
		line-height: 2rem;
		white-space: pre-wrap;
		word-wrap: break-word;
		line-break: anywhere;
		margin: 1.25rem;
		margin-top: 1rem;
	}

	.tb_install {
		font-size: 10pt;
		width: 100%;
		border-top: 1px solid #000;
		border-left: 1px solid #000;
	}

	.tb_install th {
		background-color: rgb(216, 216, 216);
		border-bottom: 1pt solid windowtext;
		border-right: 1pt solid windowtext;
	}

	.tb_install td {
		padding-left: 0.25rem;
		border-bottom: 1pt solid windowtext;
		border-right: 1pt solid windowtext;
		line-height: 1.5rem;
		white-space: pre-wrap;
		word-wrap: break-word;
		line-break: anywhere;
	}

	.tb_install td img {
		width: 100%;
	}

	.tb_install tr td:first-child {
		text-align: center;
	}
</style>
<style>
	#app {
		min-width: 100% !important;
	}
</style>
